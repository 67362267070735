import {
  Box,
  Button,
  Divider,
  Grid2,
  IconButton,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { BackgroundImage, Carousel, Container, Image } from '../../components';
import {
  home as homeAssets,
  aboutUs as aboutUsAssets,
  icon as iconAssets,
  partner as partnerAssets
} from '../../assets';
import { CAROUSEL_CONTENT } from '../AboutUs/utils';
import { PlayCircleOutline } from '@mui/icons-material';
import { useNavigate ,useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

export const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = `https://www.bluesagepools.com${location.pathname}`;

  return (
    <>
     <Helmet>
        <title>Blue Sage Pools - Transform Your Backyard</title>
        <meta
          name="description"
          content="Discover top-notch pool design, maintenance, and renovation services. Transform your backyard into a personalized oasis with Blue Sage Pools in Texas."
        />
        <meta
          name="keywords"
          content="pool design Texas, backyard oasis, outdoor kitchen design, pool renovation, pool maintenance service, custom pool builders, Blue Sage Pools, swimming pool experts Texas"
        />
        <meta
          property="og:title"
          content="Blue Sage Pools - Transform Your Backyard"
        />
        <meta
          property="og:description"
          content="Turn your backyard dreams into reality with Blue Sage Pools. We specialize in pool design, maintenance, and renovation across Texas."
        />
        <meta
          property="og:image"
          content={homeAssets.topImage1} // Replace with a relevant image URL
        />
        <meta property="og:url" content="https://bluesagepools.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href={currentUrl} />
      </Helmet>


      <Button
        variant="contained"
        onClick={() => navigate('/schedule-now')}
        sx={{
          p: 1.5,
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          position: 'fixed',
          zIndex: '10',
          top: '70%',
          transformOrigin: 'left top',
          rotate: '270deg'
        }}
      >
        Schedule Now
      </Button>
      <Box sx={{ position: 'relative', zIndex: '11', height: '100vh' }}>
        <Carousel showArrows={false}>
          {[...Array(3).keys()].map((index) => (
            <BackgroundImage
              sx={{ height: '100vh' }}
              src={homeAssets[`topImage${index + 1}`]}
              alt="Blue Sage Pools"
            />
          ))}
        </Carousel>
        <Box
          sx={{
            background: 'rgba(0,0,0,0.25)',
            position: 'absolute',
            inset: 0,
            height: '100%'
          }}
        >
          <Container
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: { xs: 2, sm: 3, md: 4 },
              color: 'common.white'
            }}
          >
            <Typography
              variant="caption"
              color="common.white"
              sx={{ '&::before': { backgroundColor: 'common.white' } }}
            >
              Blue sage design team
            </Typography>
            <Typography variant="h3">
              Transforming your entire backyard into a personalized oasis
            </Typography>
            <Typography>
              Turn your thoughts and dreams into reality with the help of our
              talented designers.
            </Typography>
            <Typography component="a" href="tel:4695272583">
              <Button
                variant="contained"
                sx={{
                  width: 'max-content',
                  p: 2,
                  backgroundColor: 'grey.500'
                }}
              >
                Call Now!
              </Button>
            </Typography>
          </Container>
        </Box>
      </Box>
      <Container sx={{ my: { xs: 5, sm: 10, md: 15 } }}>
        <Image
          sx={{ width: 'clamp(25rem, 100%, 40rem)', mx: 'auto' }}
          src={aboutUsAssets.weKnowPools}
          alt="We Know Pools"
        />
        <Grid2 container spacing={2} sx={{ mt: { xs: 2, sm: 3, md: 4 } }}>
          <Grid2 size={{ xs: 12, sm: 6 }}>
            <Typography variant="caption">
              welcome to blue sage pools
            </Typography>
            <Typography
              variant="paragraph"
              textAlign="justify"
              sx={{ mt: { xs: 2, sm: 3, md: 4 } }}
            >
              <strong>
                Experience the best in pool care maintenance, service,
                construction, and renovation.
              </strong>
              Whether you need weekly pool care maintenance or are considering
              owning a new swimming pool, we are your one-stop solution.
            </Typography>
            <Typography
              variant="paragraph"
              textAlign="justify"
              sx={{ mt: { xs: 2, sm: 3, md: 4 } }}
            >
              Our team, with a combined 55 years of experience, throughout DFW
              and The State of Texas is dedicated to enhancing, maintaining, and
              bringing your vision of an extraordinary backyard oasis to life.
            </Typography>
            <Typography
              variant="paragraph"
              textAlign="justify"
              sx={{ mt: { xs: 2, sm: 3, md: 4 } }}
            >
              <strong>READ OUR “ABOUT US” PAGE -</strong> As your neighborhood
              experts, we have lived and served in this area for decades. We
              specialize in service installation and repair, weekly maintenance,
              pool design, spa installation, and outdoor living construction.
              Contact us today for a free estimate and let us help you create
              the perfect backyard for your home.
            </Typography>
          </Grid2>
          <Grid2
            size={{ xs: 12, sm: 6 }}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Image
              sx={{ maxWidth: '50rem' }}
              src={homeAssets.img2}
              alt="Blue Sage pools"
            />
            <Image
              sx={{ maxWidth: '50rem', mt: { xs: 2, sm: 3, md: 4 } }}
              src={homeAssets.img3}
              alt="Blue Sage pools"
            />
          </Grid2>
          <Grid2 size={12} textAlign="center">
            <Button
              variant="contained"
              sx={{
                mt: { xs: 2, sm: 3, md: 4 },
                py: 2,
                px: 3,
                width: 'max-content'
              }}
              onClick={() => navigate('/contact')}
            >
              Contact Us
            </Button>
          </Grid2>
        </Grid2>
      </Container>
      <Box
        sx={{
          py: { xs: 5, sm: 10, md: 15 },
          backgroundColor: 'primary.main',
          color: 'common.white'
        }}
      >
        <Container sx={{ textAlign: 'center' }}>
          <Typography variant="h3" sx={{ my: 4 }} textAlign="center">
            The Blue Sage Pool's Promise
          </Typography>
          <Stack
            direction="row"
            flexWrap="wrap"
            gap={5}
            justifyContent="space-evenly"
            alignItems="center"
            sx={{ my: { xs: 2, sm: 3, md: 4 } }}
          >
            <Stack spacing={2} alignItems="center">
              <Image
                src={iconAssets.hardWork}
                alt="Pool"
                sx={{ width: '6rem' }}
              />
              <Typography variant="caption2" color="common.white">
                Hard Work
              </Typography>
            </Stack>
            <Stack spacing={2} alignItems="center">
              <Image
                src={iconAssets.integrity}
                alt="Park"
                sx={{ width: '6rem' }}
              />
              <Typography variant="caption2" color="common.white">
                Integrity
              </Typography>
            </Stack>
            <Stack spacing={2} alignItems="center">
              <Image
                src={iconAssets.commitment}
                alt="Maintenance"
                sx={{ width: '6rem' }}
              />
              <Typography variant="caption2" color="common.white">
                Commitment
              </Typography>
            </Stack>
            <Stack spacing={2} alignItems="center">
              <Image
                src={iconAssets.organized}
                alt="Remodel"
                sx={{ width: '6rem' }}
              />
              <Typography variant="caption2" color="common.white">
                Organized
              </Typography>
            </Stack>
            <Stack spacing={2} alignItems="center">
              <Image
                src={iconAssets.achivement}
                alt="Products"
                sx={{ width: '6rem' }}
              />
              <Typography variant="caption2" color="common.white">
                Achivement
              </Typography>
            </Stack>
          </Stack>
          <Typography variant="paragraph" textAlign="center">
            Blue Sage Pools is committed to providing each customer with
            unmatched professionalism and excellence at every step of the way.
            Our company’s core values define our culture, help foster innovative
            solutions, provide world-class customer service and produce some of
            the best pools in the industry.
          </Typography>
          {/* <Typography variant="paragraph">
            We know the frustrations and needs of pool owners in N. Texas,
            because we have been working with you for decades!!
          </Typography> */}
          <Button
            variant="contained"
            sx={{
              mt: { xs: 2, sm: 3, md: 4 },
              py: 2,
              px: 3,
              backgroundColor: 'grey.500'
            }}
            onClick={() => navigate('/service')}
          >
            More services
          </Button>
        </Container>
      </Box>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: 'grey.200',
          p: { xs: 3, sm: 6, md: 9, lg: 12 },
          position: 'relative',
          color: 'common.white',
          '&::before': {
            content: "''",
            backgroundColor: 'grey.900',
            position: 'absolute',
            inset: 0,
            display: 'block',
            height: '100%',
            width: { xs: '100%', sm: '70%' }
          }
        }}
      >
        <Box
          sx={{
            position: 'relative',
            zIndex: 1,
            width: { xs: '100%', sm: '90%' },
            maxWidth: '150rem'
          }}
        >
          <Typography variant="h5" textAlign="center">
            Bring your vision to life
          </Typography>
          <Carousel sx={{ my: { xs: 2, sm: 3, md: 5 } }}>
            {[...Array(7).keys()].map((index) => (
              <Stack
                key={`Key-${index}`}
                justifyContent="center"
                sx={{ height: '100%', maxHeight: '70vh' }}
              >
                <Image
                  sx={{ flex: 1 }}
                  src={homeAssets[`carousel${index + 1}`]}
                  alt="Pool"
                />
              </Stack>
            ))}
          </Carousel>
        </Box>
      </Stack>
      <Divider
        sx={{ p: { xs: 2, sm: 4, md: 6 }, backgroundColor: 'grey.200' }}
      />
      <Box
        sx={{
          backgroundImage: `url(${aboutUsAssets.background})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        }}
      >
        <Box sx={{ backdropFilter: 'blur(1rem)' }}>
          <Container sx={{ py: { xs: 5, sm: 10, md: 15 } }}>
            <Grid2 container spacing={4}>
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <Typography
                  variant="caption"
                  color="common.white"
                  sx={{ '&::before': { backgroundColor: 'common.white' } }}
                >
                  Blue Sage Pools
                </Typography>
                <Typography sx={{ my: 4 }} variant="h3" color="common.white">
                  top reasons to contact us today!
                </Typography>
                <Typography color="common.white">
                  "It starts with over 55 years of combined experience in DFW
                  and The State of Texas"
                </Typography>
                <Typography
                  color="common.white"
                  variant="h5"
                  textTransform="uppercase"
                  sx={{ mt: 4 }}
                >
                  We know pools!
                </Typography>
                <Button
                  fullWidth
                  sx={{ py: 2, px: 3, mt: 4 }}
                  variant="contained"
                  onClick={() => navigate('/service')}
                >
                  Services
                </Button>
              </Grid2>
              <Grid2
                size={{ xs: 12, sm: 6 }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <List
                  sx={{
                    backgroundColor: 'common.white',
                    width: { xs: '100%', sm: '90%' },
                    px: 2,
                    py: 4
                  }}
                >
                  <ListItem sx={{ my: 2, display: 'flex', gap: 7 }}>
                    <Typography>01.</Typography>
                    <Typography fontWeight={300}>
                      <strong>Expert</strong> Pool Maintenance & Repair
                    </Typography>
                  </ListItem>
                  <Divider sx={{ width: '60%', mx: 'auto' }} />
                  <ListItem sx={{ my: 2, display: 'flex', gap: 7 }}>
                    <Typography>02.</Typography>
                    <Typography fontWeight={300}>
                      <strong>Experienced</strong> Service Department
                    </Typography>
                  </ListItem>
                  <Divider sx={{ width: '60%', mx: 'auto' }} />
                  <ListItem sx={{ my: 2, display: 'flex', gap: 7 }}>
                    <Typography>03.</Typography>
                    <Typography fontWeight={300}>
                      <strong>Personalized</strong> & Reliable Service
                    </Typography>
                  </ListItem>
                  <Divider sx={{ width: '60%', mx: 'auto' }} />
                  <ListItem sx={{ my: 2, display: 'flex', gap: 7 }}>
                    <Typography>04.</Typography>
                    <Typography fontWeight={300}>
                      <strong>Comprehensive</strong> Construction Services
                    </Typography>
                  </ListItem>
                  <Divider sx={{ width: '60%', mx: 'auto' }} />
                  <ListItem sx={{ my: 2, display: 'flex', gap: 7 }}>
                    <Typography>05.</Typography>
                    <Typography fontWeight={300}>
                      <strong>Valuable</strong> Feedback & Recommendations
                    </Typography>
                  </ListItem>
                </List>
              </Grid2>
            </Grid2>
          </Container>
        </Box>
      </Box>
      <Container sx={{ my: { xs: 5, sm: 10, md: 15 } }}>
        <Typography variant="h3" textAlign="center" sx={{ my: 3 }}>
          What people say
        </Typography>
        <Carousel showArrows>
          {CAROUSEL_CONTENT.map(({ paragraph, author }) => (
            <Stack
              key={author}
              sx={{ height: '100%' }}
              spacing={{ xs: 2, sm: 4 }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h3" color="grey.400">
                "
              </Typography>
              <Typography
                fontStyle="italic"
                fontWeight={300}
                fontSize={{ xs: '1.4rem', sm: '2rem' }}
                width="max(30rem, 90%)"
              >
                {paragraph}
              </Typography>
              <Typography variant="caption2" component="p">
                {author.split(', ').map((val, index) => {
                  if (index === 0)
                    return (
                      <Typography
                        key={`key-${index}`}
                        variant="caption2"
                        color="common.black"
                      >
                        {`${val}, `}
                      </Typography>
                    );
                  return index === 1 ? val : `, ${val}`;
                })}
              </Typography>
            </Stack>
          ))}
        </Carousel>
      </Container>
      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="space-evenly"
        alignItems="center"
        gap={2}
        sx={{ mt: { xs: 2, sm: 4, md: 6 }, mb: 1 }}
      >
        {[...Array(5).keys()].map((index) => (
          <Image
            key={`key-${index}`}
            src={partnerAssets[`partner${index + 1}`]}
            sx={{ width: 'clamp(15rem, 15%, 30rem)' }}
            alt="Partner"
          />
        ))}
      </Stack>
      <Box
        sx={{
          py: { xs: 5, sm: 10, md: 15 },
          backgroundColor: 'primary.main',
          color: 'common.white'
        }}
      >
        <Container>
          <Typography
            variant="caption"
            color="common.white"
            sx={{ '&::before': { backgroundColor: 'common.white' } }}
          >
            The blue sage pool difference
          </Typography>
          <Typography variant="h3" sx={{ my: 4 }}>
            Design Guide
          </Typography>
          <Typography variant="paragraph">
            <strong>From vision to virtual reality,</strong> our designers
            skillfully translate your ideas into detailed 3D models. Beautiful
            designs can sometimes come with hidden maintenance issues, our
            experienced team can help guide you in your design choices and help
            you avoid those pitfalls. This immersive visualization allows you to
            explore and refine your dream space, ensuring every detail is just
            right before construction begins.
          </Typography>
          <Typography variant="paragraph">
            <strong>Our Process and Commitment</strong> It all starts with
            you—your dream pool, backyard, or outdoor kitchen. We listen to your
            ideas, offer expertise, and then deliver our commitment you and your
            family. Our Design Consultants will visit your property to take
            photos, measurements, and conduct a crucial survey.
          </Typography>
          <BackgroundImage
            sx={{
              width: '100%',
              mt: 6,
              height: '30rem',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              gap: 5,
              justifyContent: 'center',
              alignItems: 'center'
            }}
            src={homeAssets.videoBackground}
            alt="Blue Sage Pools"
          >
            <Tooltip arrow title="Play video">
              <IconButton sx={{ color: 'common.white' }}>
                <PlayCircleOutline
                  fontSize="large"
                  sx={{ transform: 'scale(2.5)' }}
                />
              </IconButton>
            </Tooltip>
            <Typography variant="caption2" color="common.white">
              Pool design studio
            </Typography>
          </BackgroundImage>
          <Box textAlign="center" sx={{ mt: { xs: 4, sm: 6, md: 10 } }}>
            <Button
              variant="contained"
              sx={{
                py: 2,
                px: 3,
                backgroundColor: 'grey.500'
              }}
              onClick={() => navigate('/gallery')}
            >
              Gallery
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};
