const LINKS = [
  {
    id: 'home',
    to: '/',
    label: 'Home'
  },
  {
    id: 'gallery',
    to: '/gallery',
    label: 'Gallery'
  },
  {
    id: 'aboutus',
    to: '/about-us',
    label: 'About Us'
  },
  {
    id: 'financing',
    label: 'Financing',
    to: 'https://www.lyonfinancial.net/apply/',
    target: '_blank'
  },
  {
    id: 'service',
    to: '/service',
    label: 'Service'
  },
  {
    id: 'plans',
    to: '/plans',
    label: 'Plans'
  },
  {
    id: 'contact',
    to: '/contact',
    label: 'Contact'
  }
];

export { LINKS };
