import { Link as RouterLink } from 'react-router-dom';
import { Typography } from '@mui/material';

export const Link = ({ to, text, target, color, sx }) => (
  <RouterLink to={to} target={target} style={{ textDecoration: 'none' }}>
    <Typography color={color || 'common.white'} sx={sx}>
      {text}
    </Typography>
  </RouterLink>
);
