import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  Stack,
  Toolbar,
  useTheme
} from '@mui/material';
import { Close, Menu } from '@mui/icons-material';
import { LINKS } from './utils';
import { aboutUs as aboutUsAssets } from '../../assets';
import { useScrollY } from '../../hooks';
import { Container, Image, Link } from '../../components';

export const Navbar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { pathname } = useLocation();

  const scrollY = useScrollY();
  const [hasBackground, setHasBackground] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (scrollY === 0) {
      setHasBackground(false);
    } else if (!hasBackground) {
      setHasBackground(true);
    }
  }, [scrollY, hasBackground, setHasBackground]);

  return (
    <>
      <AppBar
        position="fixed"
        component="nav"
        sx={{
          py: { xs: 2, md: 4 },
          ...(!hasBackground && { backgroundColor: 'transparent' })
        }}
      >
        <Container>
          <Toolbar
            sx={{
              transition: 'all 0.1s ease-in',
              justifyContent: 'flex-end'
            }}
          >
            <IconButton
              sx={{
                color: theme.palette.common.white,
                display: { xs: 'initial', md: 'none' }
              }}
              onClick={() => setOpenDrawer((prev) => !prev)}
            >
              <Menu fontSize="large" />
            </IconButton>
            {LINKS.map((link) => (
              <Link
                key={link.id}
                to={link.to}
                text={link.label}
                target={link.target}
                sx={{
                  display: { xs: 'none', md: 'block' },
                  mx: 2,
                  px: 0.5,
                  fontFamily: 'inherit',
                  borderBottom: '2px solid',
                  borderColor: 'transparent',
                  ...(pathname === link.to && {
                    borderColor: theme.palette.common.white
                  }),
                  transition: 'all 0.1s linear',
                  '&:hover': {
                    borderColor: theme.palette.common.white
                  }
                }}
              />
            ))}
            <Button
              disableRipple
              onClick={() => navigate('schedule-now')}
              variant="contained"
              sx={{
                display: { xs: 'none', md: 'block' },
                p: 2,
                ml: 4,
                backgroundColor: theme.palette.grey[500]
              }}
            >
              Schedule Now
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{ sx: { backgroundColor: 'transparent' } }}
      >
        <Box
          sx={{
            p: { xs: 2, sm: 4 },
            maxWidth: '90%',
            backgroundColor: 'primary.main',
            height: '100%'
          }}
        >
          <Stack direction="row" spacing={5} alignItems="center">
            <Image
              sx={{ maxWidth: '40rem' }}
              src={aboutUsAssets.weKnowPools}
              alt="Blue Sage Pools"
            />
            <IconButton
              sx={{
                color: theme.palette.common.white
              }}
              onClick={() => setOpenDrawer(false)}
            >
              <Close fontSize="large" />
            </IconButton>
          </Stack>
          <List>
            {LINKS.map((link) => (
              <ListItem
                key={link.id}
                sx={{ justifyContent: 'center', my: 2 }}
                onClick={() => setOpenDrawer(false)}
              >
                <Link
                  to={link.to}
                  text={link.label}
                  sx={{
                    fontFamily: 'inherit',
                    borderBottom: '2px solid',
                    borderColor: 'transparent',
                    ...(pathname === link.to && {
                      borderColor: theme.palette.common.white
                    }),
                    transition: 'all 0.1s linear',
                    '&:hover': {
                      borderColor: theme.palette.common.white
                    }
                  }}
                />
              </ListItem>
            ))}
            <ListItem sx={{ justifyContent: 'center' }}>
              <Button
                disableRipple
                onClick={() => navigate('schedule-now')}
                variant="contained"
                sx={{
                  p: 2,
                  backgroundColor: theme.palette.grey[500]
                }}
              >
                Schedule Now
              </Button>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
};
